.react-datepicker-wrapper {
  margin-top: 16px;
}
.react-datepicker-wrapper input {
  width: 100%;
  border-width: 0 0 2px 0;
  border-color: #898788;
  background: transparent;
  color: #5d5e60;
  font-size: 1rem;
  font-family: 'D-din';
  font-weight: 400;
  outline: none!important;
  padding: 4px 0 3px;
}
.react-datepicker-wrapper input:focus,
.react-datepicker-wrapper input:focus-visible {
  outline: none!important;
  border-color: #f1b80e!important;
  box-shadow: none!important;
}
.react-datepicker-wrapper input::placeholder {
  color: #6c757d;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  max-width: 100%!important;
  height: auto!important;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}


.react-pdf__Page__textContent.textLayer, .react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.swal2-container {
  z-index: 10000!important;
}


.container {
  width: 488px;
  margin: 28px;
}

.file-display-container {
  width: 100%;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin: 14px 16px 14px 12px;
  line-height: 50px;
  height: 50px;
}

.file-size {
  margin-left: 4px;
  color: #444242;
}

.file-error-message {
  color: red;
}

.file-remove {
  margin-left: 16px;
  color: red;
}

.file-input {
  display: none;
}

.drop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 232px;
  border: 2px dashed #e5ddfb;
  border-radius: 4px;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.upload-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.upload-modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.progress-container {
  background: white;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.progress-container span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
}

.progress {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  height: 20px;
  border-radius: 5px;
}

.progress-bar {
  position: absolute;
  background-color: #4aa1f3;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.error {
  color: red;
}

.input-due {
  background: transparent;
  border-width: 0 0 2px;
  border-color: #898788;
  padding: 4px 0 10px;
  font-family: D-din;
  font-weight: 400;
  font-size: 1rem;
  color: #5d5e60;
}

.input-due:focus {
  outline: none!important;
  border-color: #f1b80e;
}

input.input-due[disabled] {
  border-bottom-style: dotted;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}